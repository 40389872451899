import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import Peer from 'peerjs';

function App() {
  const [peerId, setPeerId] = useState('');
  const [remotePeerIdValue, setRemotePeerIdValue] = useState('');
  const [remoteIdList, setRemoteIdList] = useState([])
  const currentVideoRef = useRef()
  const [videoRefs, setVideoRefs] = useState([]);
  const [pushingVideo, setPushingVideo] = useState(false)

  const maxVideos = 4
  const [peerInstance, setPeerInstance] = useState(null);
  const [amIHost, setAmIHost] = useState(false)
  const [calling, setCalling] = useState(0) // 0 (no se está llamando) 1 llamdao al uno, 2 llamndo al 2
  //const [message, setMessage] = useState('');

  const addVideoRef = (stream, peerId, isIncomingCall = false) => {
    setPushingVideo( isPushig => {
      setVideoRefs( videoRefs => {
        if (videoRefs.length === 0 && isIncomingCall) setAmIHost(true)
        if (!isPushig && videoRefs.length < maxVideos) {
          videoRefs.push(null)
          setRemoteIdList(list => {
            if (!list.includes(peerId)) list.push(peerId)
            return list
          })
          isPushig = true
          setTimeout(() => {
            setVideoRefs( videoRefs => {
              setAmIHost(amI => {
                if (amI) sendIds()
                else {
                  if (!isIncomingCall){
                    setCalling(calling => {
                      if (calling === 0) return 0
                      return calling + 1
                    })
                  }
                }
                return amI
              })
              videoRefs[videoRefs.length-1].srcObject = stream
              videoRefs[videoRefs.length-1].play()
              setPushingVideo(false)
              return videoRefs
            })
          }, 300)
        }
        return videoRefs
      })
      return isPushig
    })
  }

  useEffect(() => {
    if (calling > 0) {
      console.log("Is Calling", calling)
      console.log(remoteIdList[calling-1])
      call(remoteIdList[calling-1])
      console.log(remoteIdList)
    }

  }, [calling])

  useEffect(() => {
    const peer = new Peer();
    setPeerInstance(peer);

    peer.on('open', (id) => {
      setPeerId(id);
    });


    peer.on('call', (call) => {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then((mediaStream) => {
          setVideoRefs((prevRefs) => {
            currentVideoRef.current.srcObject = mediaStream;
            currentVideoRef.current.muted = true;
            currentVideoRef.current.play();
            call.answer(mediaStream);
            return prevRefs;
          });

          call.on('stream', (remoteStream) => {
            //const peerId = call.peer
            //console.log("id del peer remoto: ", peerId)
            addVideoRef(remoteStream, call.peer, true)
          });
        });
    });

    peer.on('connection', (conn) => {
      conn.on('data', (data) => {
        setCalling(1)
        setRemoteIdList(() => data)
      });
    });

    return () => {
      peer.destroy();
    };
  }, []);

  const call = (remotePeerId) => {
    console.log("train to call", remotePeerId)
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setVideoRefs((prevRefs) => {
          currentVideoRef.current.srcObject = mediaStream;
          currentVideoRef.current.muted = true;
          currentVideoRef.current.play();
          setPeerInstance(peerInstance => {
            const call = peerInstance.call(remotePeerId, mediaStream);
            call.on('stream', (remoteStream) => {
              addVideoRef(remoteStream, call.peer)
            });
            return peerInstance
          })

          return prevRefs;
        });
      });
  };

  const IdList = () => {
    let lista = remoteIdList.map((id, index) => {
      return(
        <div key={index}>
          <p>{id}</p>
        </div>
      )
    })
    return lista
  }

  const sendIds = () => {
    setPeerInstance(instance => {
      setRemoteIdList(idList => {
        if (idList.length <= 1) return idList
        let conn = instance.connect(idList[idList.length-1])
        let idListToSend = idList.slice(0,-1)

        conn.on("open", () => {
          conn.send(idListToSend)
        })
        return idList
      })
      return instance
    })
  }

  return (
    <div className="App">
      <h1>Current user id is {peerId}</h1>
      <button onClick={() => navigator.clipboard.writeText(peerId)}>copy</button>
      <input className='peer-id-input' type="text" value={remotePeerIdValue} onChange={(e) => setRemotePeerIdValue(e.target.value)} />
      <button onClick={() => call(remotePeerIdValue)}>Call</button>
      <button onClick={() => setCalling(calling => true)}>Call</button>
      <div id='cam-container'>
        <div>
          <video ref={currentVideoRef} className='cam'/>
        </div>
        {videoRefs.map((_, index) => (
          <div key={index}>
            <video ref={(ref) => setVideoRefs((prevRefs) => {
              prevRefs[index] = ref;
              return prevRefs;
            })} className='cam' />
          </div>
        ))}
      </div>
      {remoteIdList && <IdList />}
    </div>
  );
}

export default App;
